<template>
    <div v-show="pageReady" id="login">
        <div class="heading">Living Report Login</div>
        <input type="text" name="username" v-model="username" placeholder="Username" />
        <input type="password" name="password" v-model="password" v-on:keyup.enter="login()" placeholder="Password"/>
        <button type="button" @click="login()">Login</button>
        <span style="margin-left:1rem;color: red">{{ message }}</span>
    </div>
</template>



<script>
    import axios from 'axios';
    import api from '@/api.js';
    import { EventBus } from '@/components/eventbus'

    export default {
        name: 'Login',
        data() {
            return {
                username:"",
                password: "",
                message: "",
                pageReady: false
            }
        },
        methods: {

            async login() {
                if(this.username != "" && this.password != "") {
                    const isCorrect = await api.login(this.username,this.password)
                    if(isCorrect.data.result == true) {
                        EventBus.$emit('setIsAuthenticated',true )

                        // this.$router.replace({ name: "report" });
                        this.$router.replace('landing')
                    } else {
                        this.message = "Login failed";
                    }
                } else {
                    this.message = "Please enter username and password";
                }
            }
        },

        beforeCreate(){
            api.checkAuthenticated()
            .then ( res=>{
                if (res.data.authenticated==true){
                    EventBus.$emit('setIsAuthenticated',true )
                    this.$router.replace('landing')

                }
                else{
                  // Only show login after authentication check
                  this.pageReady=true
                }
            })
        }
    }
</script>


<style scoped>
  #login {
      border: 1px solid #CCCCCC;
      background-color: #FFFFFF;
      margin: auto;
      margin-top: 200px;
      padding: 2rem;
      border-radius: 6px;
  }
  #login .heading{
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
  }

  #login input{
    display: block;
    margin-bottom: 1rem;
    width:100%;
  }

  #login button {
    font-family:inherit!important;
    font-size: 1.2rem;
  }

</style>