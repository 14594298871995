import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import router from './router'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)

import Vuex from 'vuex'
Vue.use(Vuex)

import VueKatex from 'vue-katex'
import 'katex/dist/katex.min.css'
Vue.use(VueKatex, {globalOptions: {}})

import store from '@/store/index'

import filters from '@/filters.js'
for(const filterName in filters){
  Vue.filter(filterName, filters[filterName])
}

Vue.config.productionTip = false
var errorsToSkip = ["Cannot read property 'badInput' of undefined", "Cannot read properties of undefined"]

// Hide vuematerial dropdown error
Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    // Show any error but these ones
    if (errorsToSkip.indexOf(err.message) != -1) {
      console.error(err);
    }
  }
};

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
