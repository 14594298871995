<template>
  <div class="report">
    <div class="title">
      <div class="title__text"> Analysis of</div>
      <div class="district__name"> Sealing Activity in Patent Cases</div>
      <div class="title__update">(Updated: {{latest_update}})</div>
    </div>

    <div class="toc">
      <div class="toc__title"> Contents </div>
      <ul>
        <li v-for="el in sectionHeaders">
          <a class="sectionLink" @click="scroll('#'+el.id)"> {{ el.innerHTML }} </a>
        </li>
      </ul>
    </div>

    <h2 id="section1">1. Description </h2>

    <div class="block">
      US court documents are generally open to public view, but litigants can
      request that some documents be sealed from public access.
      This is a common request when the documents contain sensitive information.
      Patent cases are one area of civil litigation where records are frequently sealed,
      as questions involving trade secrets are often at the core of these kinds of cases.
    </div>

    <div class="block">
      However, not all sealed documents should necessarily be sealed. Both parties
      may want to seal filings for reasons unrelated to the sensitivity of the underlying information.
      For example, a plaintiff may want to seal information related to the current case to maintain a competitive advantage against future defendants,
      such as the plaintiff’s theory of the patent’s scope.
      Defendants may similarly want to limit access to case records to prevent other parties
      from discovering, for example, the reasons why the patent is alleged to be invalid.
    </div>

    <div class="block">
      Thus, both plaintiffs and defendants can have private interests that may be
      insufficient to overcome the public’s interest in open court records – and are thus
      insufficient to convince a judge to seal the documents at issue.
      Indeed, some districts have enacted local patent rules and created form protective orders
      to help judges consistently balance the public’s interest in open records with
      individual litigants’ privacy interests.
    </div>

    <div class="block">
      For these reasons, we wanted to examine sealing activity in patent cases across
      all 94 districts to understand if there is quantifiable variation in sealing practices across courts.
    </div>

    <h2 id="section2"> 2. Data </h2>
    <div class="block">
      The data for this analysis was collected from PACER.gov.
      It consists of all cases filed in 2016 with the nature of suit '<span class="fig">830: Patent</span>' across all 94 district courts.

    </div>

    <h2 id="section3"> 3. Methodology </h2>

    <div class="block">

       Using a set of known terms (‘seal’, ‘restrict’, ‘redact’, and ‘protective’),
       an algorithm was developed to identify the universe of potential actions on the docket that relate to <em>sealing activity</em>.
    </div>
    <div class="block">
      Within sealing activity, the following specific types of events were identified:
      <ul>
        <li> <span class="bold-term"> Motions to Seal</span>: motions by a party to have a particular document or documents in the case docket sealed
        <li> <span class="bold-term"> Sealed Items</span>: docket items that are either sealed or restricted and that fall into the one of the following
        categories:
        <ul>
          <li>document,</li>
          <li>attachment</li>
          <li>reply</li>
          <li>response</li>
          <li>transcript</li>

        </ul>
        </li>
      </ul>
    </div>

    <div class="block">
      To compare the rate of sealing across groups we constructed the <span class="bold-term">Sealed Items / Motions</span> ratio.
      For a given group of cases the ratio of Sealed Items over Motions is calculated as:
    </div>

    <div class="latex" v-katex:auto>
      \(\text{Sealed Items/Motions} = \frac{ \text{Total no. of sealed items } }{ \text{Total no. of motions to seal }}\)
    </div>

    <div class="block">
      All code is available at: <img class="zenodo" src="https://zenodo.org/badge/DOI/10.5281/zenodo.5056441.svg"> </img>
    </div>

    <h2 id="section4"> 4. Patent Local Rules </h2>

    <div class="block">
      Patent local rules are district-specific rules that apply to patent litigation procedure.
      In our analysis of sealing activity we group districts by whether or not a district is a PLR district.
    </div>

    <div class="block">
      <details class="footnote">
        <summary> Identifying PLR districts</summary>
          PLR Districts were identified using
          <a href="http://www.localpatentrules.com/wp-content/uploads/2017/08/Chart Infringement Contentions.pdf"> this </a>
            list from PatentLocalRules.com. The districts are:
          <ul>
            <li> California, Northern District </li>
            <li> California, Southern District </li>
            <li> Florida, Northern District </li>
            <li> Georgia, Northern District </li>
            <li> Idaho </li>
            <li> Illinois, Northern District </li>
            <li> Indiana, Northern District </li>
            <li> Indiana, Southern District </li>
            <li> Maryland </li>
            <li> Massachusetts </li>
            <li> Missouri, Eastern District </li>
            <li> Nevada </li>
            <li> New Hampshire </li>
            <li> New Jersey </li>
            <li> New York, Eastern District </li>
            <li> New York, Northern District </li>
            <li> New York, Southern District </li>
            <li> North Carolina, Eastern District </li>
            <li> North Carolina, Western District </li>
            <li> Ohio, Northern District </li>
            <li> Ohio, Southern District </li>
            <li> Pennsylvania, Western District </li>
            <li> Tennessee, Western District </li>
            <li> Texas, Eastern District </li>
            <li> Texas, Northern District </li>
            <li> Texas, Southern District </li>
            <li> Utah </li>
            <li> Washington, Eastern District </li>
            <li> Washington, Western District </li>

          </ul>
      </details>
    </div>


    <div class="facetgrid">
      <div class="facetgrid__row">
        <!-- <Plot v-bind="graph2" v-if="pageReady"  :width="700" /> -->
        <Plot v-bind="graph1" v-if="graph1" :key="'graph1'"   :width="450" />
        <Loader v-else :width="450" :height="500"/>

      </div>
      <div class="facetgrid__title"> Figure 1 </div>


    </div>
    <div class="block">
      Figure 1 shows the difference in Sealed Items / Motions  between
      PLR and non-PLR districts.
    </div>


    <h2 id="section5"> 5. Court Comparison by PLR </h2>

    <div class="block">
      Examining individual districts reveals even greater variation.
      Using the dropdown we make it possible to plot any one district against the aggregated PLR and
      non-PLR districts (districts must have a minimum of 5 cases in order to be plotted).
    </div>

    <div class="facetgrid">
      <div class="facetgrid__row">
        <!-- <Plot v-bind="graph2" v-if="pageReady"  :width="700" /> -->
        <Plot v-bind="graph2" v-if="graph2" :key="'graph2'"   :width="700" />
        <Loader v-else :width="700" :height="500"/>

      </div>
      <div class="facetgrid__title"> Figure 2 </div>
    </div>

    <div class="controlPanel controlPanel--centered">
      <md-field class="inline" style="width:100px">
        <label for="year-select"> Court </label>
        <md-select v-model="selectedgraph2Court" name="year-select">
          <md-option v-for="courtOption in graph2Options.court" v-bind:value="courtOption.val">
            {{ courtOption.display }}
          </md-option>
        </md-select>
        <!-- <span class="qmarkWrapper"> <i class="material-icons" style="color: orange;">help</i></span> -->
      </md-field>
    </div>
    <div class="block">
      Figure 2 shows the difference in Sealed Items / Motions between
      PLR and non-PLR districts while removing the <span class="fig"> {{selectedgraph2Court | uppercase}}</span> district.
    </div>

    <!-- <h2 id="section6"> 6. Sealing Activity during Case Span </h2>
    <div class="block">
      For closed cases with protective orders, we can construct a timeline for a case
      and categorize sealing events into one of the following buckets:
      <ul>
        <li> <span class="bold-term"> Before </span> protective order </li>
        <li> <span class="bold-term"> Protective </span> order </li>
        <li> <span class="bold-term"> After </span> protective order </li>
      </ul>

    </div>
    <div class="block">
      To compare across cases, docket events can then be placed on a normalised timeline
      between 0 and 1 by calculating:

      <div class="latex" v-katex:auto>
        \( \frac{\text{Date of Docket Entry } - \text{ Case Filing Date} }{ \text{Case Length }}\)
      </div>

      For example, an event that occurs on day 30 of a case that lasts 100 days would
      be placed at <span class="fig">0.3</span> on the timeline.

    </div> -->

    <!-- <div class="block">
      <div class="facetgrid">
        <div class="facetgrid__row">
          <Plot v-bind="graph3_0" v-if="graph3_0" :key="'graph3_0'"   :width="950" :height="500" />
          <Loader v-else :width="950" :height="700"/>

        </div>
        <div class="facetgrid__title"> Figure 3 </div>
      </div>

      Figure 3 shows sealing events across all <span class="bold-term">PLR districts</span> within the normalized case span,
      broken down by the three event buckets. Each point represents a single docket line
      that had sealing activity (one or more sealing events were flagged).

    </div>
    <div class="block">
      <div class="facetgrid">
        <div class="facetgrid__row">
          <Plot v-bind="graph3_1" v-if="graph3_1" :key="'graph3_1'"   :width="950" :height="500" />
          <Loader v-else :width="950" :height="700"/>

        </div>
        <div class="facetgrid__title"> Figure 4 </div>
      </div>

      Figure 4 similarly shows sealing events across all <span class="bold-term">non-PLR districts</span>.
    </div>
    <div class="block">
      By comparison, Figure 3 shows that there is much more sealing activity happening
      after protective orders in PLR districts than there is in non-PLR districts.
      This is demonstrated by the dense cluster of points in the second half of the case (from 0.5 to 1) within the
      <span class="bold-term">After</span> bucket of Figure 3 compared to that of Figure 4.

    </div> -->



  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import api from '@/api.js'
import court2circuit from '@/data/court2circuit.js'


// Components
import Plot from '@/components/Plot.vue'
import Loader from '@/components/Loader.vue'
import DocketSnippet from '@/components/DocketSnippet'
import _merge from 'lodash/merge'
import _cloneDeep from 'lodash/cloneDeep'
import {graphFactory} from '@/tools.js'
export default {
  name: 'Report_sealing',
  components: {
    Plot,
    DocketSnippet,
    PulseLoader,
    Loader
  },
  props: {
    reportName: String,

  },
  data: function(){
    return {
      selectedgraph2Court: 'ilnd',
      pageReady: false,
      ready: false,
      selectedIndex: 0,
      selectedOtherCourt: 'cacd',
      selectedOtherCircuit: 'First',
      selectedGraph3Year: 2016,
      selectedGraph3Prisoner: 'NonPrisoner',
      sectionHeaders: null,
      selectedOtherCourtLeveneStatistic: null,
      selectedOtherCourtLeveneP: null,
      latest_update: '3/8/2021',
      baseBlue: "#448aff",
    }
  },

  computed: {

    graph1: function(){
      let title = 'Comparison of PLR v NON-PLR'
      let additionalLayout = {yaxis: {range: [0,5]}}
      return graphFactory(this.$store.state.graphs,
        '0', 'courtComparisonPlr', 'none', additionalLayout, ()=>title)
    },

    graph2: function(){
      // let title = typeof this.court !== undefined ? 'Non-Prisoner Cases' : 'placeholder'
      // this.graph3Options.prisoner.re
      let title_category = this.graph2Options.court.filter( p => p.val==this.selectedgraph2Court)[0].display
      let title = `${this.selectedgraph2Court.toUpperCase()} Compared to PLR/NON-PLR`
      return graphFactory(this.$store.state.graphs,
        '1', 'courtComparisonPlr', this.selectedgraph2Court, {}, ()=>title)
    },

    graph2Options: function(){
      let alternatives = this.$store.state.sealingMeta?.comparisonCourtOpts
      if (alternatives == undefined) {
        return{
          court: [
            {val: 'ilnd', display: 'ILND'},
            {val: 'txed', display: 'TXED'}
          ]
        }

      }
      else{
        let courtOpts = alternatives.map( x => {
          return {
            val:x,
            display: x.toUpperCase()
          }
        })
        return {court: courtOpts}
      }
    },

    // graph3_0: function(){
    //   let title = 'Sealing events within case span (PLR districts)'
    //   let additionalLayout = {}
    //   return graphFactory(this.$store.state.graphs,
    //     '0', 'caseSpanViolins', 'plr', additionalLayout, ()=>title)
    // },
    //
    // graph3_1: function(){
    //   let title = 'Sealing events within case span (Non-PLR districts)'
    //   let additionalLayout = {}
    //   return graphFactory(this.$store.state.graphs,
    //     '1', 'caseSpanViolins', 'non-plr', additionalLayout, ()=>title)
    // },

  },

  watch: {
    selectedgraph2Court: function(newCourt, oldCourt){
      this.$store.dispatch('getSealingCourtComparisonPlr', {
        reportName: this.reportName,
        court: this.selectedgraph2Court
      })
    }

  },

  methods:{

    scroll(selector){
      const el = document.querySelector(selector)

      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    },

    getSectionHeaders(){
      const sel = 'h1:not([tocExclude]),h2:not([tocExclude])'
      return Array.from(this.$el.querySelectorAll(sel))
    },

  },

  created(){

  },

  mounted(){
    // this.$store.dispatch('initializeIfp',  {
    //   reportName: this.reportName,
    //   court: this.court
    // })
    this.$store.dispatch('initializeSealing', {
      court: this.selectedgraph2Court
    })
    this.pageReady = true
    // console.log('page ready')
    this.sectionHeaders = this.getSectionHeaders()

    this.$store.dispatch('getSealingCourtComparisonPlr', {
      reportName: this.reportName,
      court: 'none'
    })
    .then( () => {
      this.$store.dispatch('getSealingCourtComparisonPlr', {
        reportName: this.reportName,
        court: this.selectedgraph2Court
      })
    })
    .then( () => {
      this.$store.dispatch('getPatentCaseSpans', {
        reportName: this.reportName,
      })
    })


  }
}
</script>

<style>

  .graph3SmallGrid{
    width:250px;
    height:250px;
  }
  .graph5GridOf4{
    width: 350px;
    height: 350px;
  }

  .graph5All{
   width:350px;
   height:350px;
  }

  .test_blank{
    width:350px;
    height:350px;
  }

</style>
