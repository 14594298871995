<!-- A component to wrap a Plotly plot -->

<template>
  <div v-if="uuid!=undefined" :ref="uuid" :style="cssDim">
    <!-- <pulse-loader :loading="loading" :color="baseBlue" :size="size"></pulse-loader> -->
  </div>
  <div v-else>
    blank
  </div>
</template>

<script>
import {defaultLayout, defaultConfig} from '@/config/plotly.js'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


import _merge from 'lodash/merge'
import _cloneDeep from 'lodash/cloneDeep'
export default {
  name: "Plot",
  components: {
    PulseLoader
  },
  props: {
    uuid: String,
    traces: Array,
    layout: Object,
    config: Object,
    width: Number,
    height: {type: Number, default: null},
    fontSize: {type: Number, default: null},
    margins: {type: Object, default: null }
  },
  data(){
    return{
      computedAt: null,
      loading: true,
      baseBlue: "#448aff",
      prom: null
    }
  },
  computed: {
    // Merge in from default values
    mergedLayout: function() {
      return this.mergeLayout()
    },
    mergedConfig: function() {
      return _merge(_cloneDeep(defaultConfig), this.config)
    },
    squareSize: function(){
      const max = Max(this.width, this.height)
    },
    cssDim: function(){
      let style = {}
      if (this.height){ style['height'] = `${this.height}px`}
      if (this.width){ style['width'] = `${this.width}px`}
      return style
    }
  },

  mounted() {
    if(this.uuid){
      // console.log('uuid present at mount, building plot:', this.uuid)
      this.buildPlot()
    }
    else{
      console.log('Plotvue: uuid not present at mount')
    }
  },
  watch: {
    traces: {handler: function(){ this.buildPlot()}, deep: true},
    layout: {handler: function(){ this.buildPlot()}, deep: true},
    uuid: {handler: function(){ this.buildPlot()}, deep: true}
  },
  methods: {
    buildPlot(){
      this.computedAt = Date.now()
      try{
        Plotly.react(
          // this.$refs[this.uuid],
          this.$el,
          this.traces,
          this.mergedLayout,
          this.mergedConfig
        )
        .then((result) => {
          // console.log("Returning from plot?")
          this.prom = result
          this.loading = false
        })

      }
      catch{
        console.log(`buildPlot failed for uuid:${this.uuid}`)
        debugger;
      }
    },
    mergeLayout(){
      let fontRule = {}
      if (this.fontSize!=null){
        fontRule = {
          title:{
            font: {
              size: this.fontSize
            }
          },
          xaxis: {
            title: {
              font: {
                size: this.fontSize
              }
            }
          },
          yaxis: {
            title: {
              font: {
                size: this.fontSize
              }
            }
          }
        }
      }
      let marginsRule = this.margins || {}

      let result = _merge(_cloneDeep(defaultLayout), this.layout, fontRule, this.margins)
      return result
    },


  }
}
</script>