function yaxisLayout(){
  return {
    ticks:"inside",
    fixedrange: true,
    title: {
      standoff:0
    },
    showline: true,
    mirror: true,
    zeroline: false
  }
}

export const defaultLayout = {
    // Axes
    xaxis: {
      ticks:"outside",
      fixedrange: true,
      title: {
        standoff:0
      },
      showline: true,
      mirror: true
    },
    yaxis: yaxisLayout(),
    yaxis2: yaxisLayout(),
    yaxis3: yaxisLayout(),

    // Background
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',

    // Other
    font: {
      family: "Roboto, sans-serif",
      size: 18
    },
    title: {
      margin: {t:0, r:0, b:0, l:0}
    },
    margin: {pad:0},
    hoverlabel: {
      bgcolor: '#F4C798',
      font: {
        color: '#002986',
        family: 'monospace'
      },
      bordercolor: 'darkgray'
    }
}
export const defaultConfig = {
  'displayModeBar': false
}