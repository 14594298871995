import axios from 'axios'
import App from '@/App'

const axi = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5000/' : '/',
  withCredentials: true
})

const ENDPOINTS = {
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  AUTHENTICATED: '/api/authenticated',
  USER_REPORTS: '/api/userReports',
  // GET_CSRF: '/api/getcsrf',

  // IFP
  INITIALIZEIFP: '/api/ifp/initialize',
  MAINGRAPHS: '/api/ifp/mainGraphs',
  COURT_COMPARISON: '/api/ifp/courtComparison',
  CIRCUIT_COMPARISON: '/api/ifp/circuitComparison',
  COURT_COMPARISON_OPTIONS: '/api/ifp/courtComparisonOptions',
  IFP_FIRST_GRAPH: '/api/ifp/firstGraph',

  // Sealing
  INITIALIZE_SEALING: '/api/sealing/initialize',
  SEALING_COMPARISON_PLR: '/api/sealing/courtComparisonPlr',
  PATENT_CASE_SPANS: '/api/sealing/patentCaseSpans',

  // IDB
  HEATMAP: '/api/idb/heatmap',

  //Coverage
  BARPLOT: '/api/coverage/barPlot',
  SLIDERMAP: '/api/coverage/sliderMap',
  DISTRICTSMAP: '/api/coverage/districtsMap',
}
let defaultParams = {}

export default {
  login(username, password) {
    return axi.get(ENDPOINTS.LOGIN,
      {
        params: { username, password }
      })
  },
  logout() {
    return axi.get(ENDPOINTS.LOGOUT)
  },
  checkAuthenticated() {
    return axi.get(ENDPOINTS.AUTHENTICATED)
  },
  // get_csrf() {
  //   return axi.get(ENDPOINTS.GET_CSRF)
  // },

  getUserReports() {
    return axi.get(ENDPOINTS.USER_REPORTS)
  },
  checkPwd(pwd) {
    return axi.post(ENDPOINTS.CHECKPWD, {
      "password": pwd
    })
  },

  getInitialIfp(reportName, court) {
    return axi.get(ENDPOINTS.INITIALIZEIFP,
      { params: { reportName, court } })
  },
  getIfpFirstGraph(reportName, court) {
    return axi.get(ENDPOINTS.IFP_FIRST_GRAPH,
      { params: { reportName, court } })
  },
  getMainGraphs(reportName, court) {
    return axi.get(ENDPOINTS.MAINGRAPHS,
      { params: { reportName, court } })
  },

  getCircuitComparison(reportName, baseCourt, circuit, year) {
    return axi.get(ENDPOINTS.CIRCUIT_COMPARISON,
      { params: { reportName, baseCourt, circuit, year } })
  },

  getCourtComparison(reportName, baseCourt, comparisonCourt, year) {
    return axi.get(ENDPOINTS.COURT_COMPARISON,
      { params: { reportName, baseCourt, comparisonCourt, year } })
  },

  getCourtComparisonOptions(reportName, year) {
    return axi.get(ENDPOINTS.COURT_COMPARISON_OPTIONS,
      { params: { reportName, year } })
  },

  // Sealing

  getInitialSealing(court){
    return axi.get(ENDPOINTS.INITIALIZE_SEALING,
    { params: {court}}
  )
  },

  getSealingCourtComparisonPlr(court){
    return axi.get(ENDPOINTS.SEALING_COMPARISON_PLR,
    { params: {court}})
  },

  getPatentCaseSpans(){
    return axi.get(ENDPOINTS.PATENT_CASE_SPANS,
      { params: {} }
    )
  },

  // IDB
  getHeatmap(court){
    return axi.get(ENDPOINTS.HEATMAP,
    { params: {}})
  },

  // Coverage
  getBarPlot(){
    return axi.get(ENDPOINTS.BARPLOT,
    { params: {}})
  },

  getSliderMap(){
    return axi.get(ENDPOINTS.SLIDERMAP,
    { params: {}})
  },

  getDistrictsMap(){
    return axi.get(ENDPOINTS.DISTRICTSMAP,
    { params: {}})
  },
}