import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

import api from '@/api.js'

export default new Vuex.Store({
  state: {
    ifpBaseStats: {},
    graphs: {},
    courtComparisonOptions: [],
    leveneStats: {},

    // Sealing
    sealingMeta: {},

    // IDB
    idbMeta: {},

    // Coverage
    coverageMeta: {},
    districtsMap: null,
  },

  getters: {
  },

  actions: {
    initializeIfp(context, args) {
      if (!(args.court in this.state.ifpBaseStats)) {
        api.getInitialIfp(args.reportName, args.court)
          .then(resp => {
            // debugger;
            context.commit('updateIfpBaseStats', { court: args.court, baseStats: resp.data })
          })
      }
    },
    getIfpFirstGraph(context, args) {
      return api.getIfpFirstGraph(args.reportName, args.court)
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
          // return "response after context commit"
        })
    },
    getMainGraphs(context, args) {
      return api.getMainGraphs(args.reportName, args.court)
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
          // return "response after context commit"
        })
    },
    fetchCourtComparisonOptions(context, args) {
      if (context.state.courtComparisonOptions.length == 0) {
        api.getCourtComparisonOptions(args.reportName, args.year)
          .then(resp => {
            context.commit('updateCourtComparisonOptions', resp.data.courtComparisonOptions)
          })
      }
    },
    // fetch court comparison, only calls server if not in state
    fetchCourtComparison(context, args) {
      const graphKey = 'byCourtByPrisoner2016'
      const altKeyPrefix = `${args.comparisonCourt}${args.year}`
      let makeAPICall = false

      if (!(graphKey in context.state.graphs)) {
        makeAPICall = true
      }
      else {
        // Check if any keys start with necessary prefix
        let currentAltKeys = Object.keys(context.state.graphs[graphKey])
        let matches = currentAltKeys.filter(k => k.startsWith(altKeyPrefix))
        if (matches.length == 0) {
          makeAPICall = true
        }
      }
      if (!makeAPICall) {
        return
      }
      api.getCourtComparison(args.reportName, args.baseCourt, args.comparisonCourt, args.year)
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
          context.commit('updateLevene', resp.data.leveneStats)
        })
    },
    // fetch court comparison, only calls server if not in state
    fetchCircuitComparison(context, args) {
      const graphKey = 'byCircuitByPrisoner2016'
      const altKeyPrefix = `${args.circuit}${args.year}`
      let makeAPICall = false

      if (!(graphKey in context.state.graphs)) {
        makeAPICall = true
      }
      else {
        // Check if any keys start with necessary prefix
        let currentAltKeys = Object.keys(context.state.graphs[graphKey])
        let matches = currentAltKeys.filter(k => k.startsWith(altKeyPrefix))
        if (matches.length == 0) {
          makeAPICall = true
        }
      }
      if (!makeAPICall) {
        return
      }
      api.getCircuitComparison(args.reportName, args.baseCourt, args.circuit, args.year)
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
          context.commit('updateLevene', resp.data.leveneStats)
        })
    },

    // Sealing
    initializeSealing(context,args){
      return api.getInitialSealing(args.court)
        .then(resp => {
          context.commit('updateSealingMeta', { meta: resp.data.sealingMeta })
        })
    },
    getSealingCourtComparisonPlr(context, args) {
      return api.getSealingCourtComparisonPlr(args.court)
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
        })
    },
    getPatentCaseSpans(context, args) {
      return api.getPatentCaseSpans()
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
        })
    },

    // IDB
    getHeatmap(context, args) {
      return api.getHeatmap()
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
        })
    },
    
    // Coverage
    getBarPlot(context, args) {
      return api.getBarPlot()
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
        })
    },

    getSliderMap(context, args) {
      return api.getSliderMap()
        .then(resp => {
          context.commit('updateGraphs', resp.data.graphs)
        })
    },
    getDistrictsMap(context, args) {
      return api.getDistrictsMap()
        .then(resp => {
          context.commit('updateDistrictsMap', resp.data.map)
        })
    },
  },

  mutations: {
    updateIfpBaseStats(state, payload) {
      // # Need to use Vue set for objects so that they are reactive
      Vue.set(state.ifpBaseStats, payload.court, payload.baseStats)
    },
    updateCourtComparisonOptions(state, payload) {
      state.courtComparisonOptions = payload
    },
    updateLevene(state, payload) {
      for (const statKey in payload) {
        Vue.set(state.leveneStats, statKey, payload[statKey])
      }
    },

    updateSealingMeta(state, payload) {
      // # Need to use Vue set for objects so that they are reactive
      Vue.set(state, 'sealingMeta', payload.meta)
    },
    updateDistrictsMap(state, payload){
      Vue.set(state, "districtsMap",payload)
    },
    updateGraphs(state, payload) {
      for (const graphKey in payload) {
        if (!(graphKey in state.graphs)) {
          // Update with the whole thing
          Vue.set(state.graphs, graphKey, payload[graphKey])
        }
        else {
          for (const altKey in payload[graphKey].alternatives) {
            // Update alternatives individually by altKey
            Vue.set(state.graphs[graphKey].alternatives, altKey, payload[graphKey].alternatives[altKey])
          }
          // Update the metadata if there is any
          if ('metadata' in payload[graphKey]) {
            Vue.set(state.graphs[graphKey], 'metadata', payload[graphKey].metadata)
          }
        }
      }

    }
  }

})