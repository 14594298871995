<template>
  <div class="loader__wrapper" :style="wrapperStyle">
    <pulse-loader :color="color"/>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: "Loader",
  data: function(){
    return{
      color: "#7465FF"
    }
  },
  components: {
    PulseLoader
  },
  props: {
    width: {type: Number, default: null},
    height: {type: Number, default: null}
  },
  computed: {
    wrapperStyle: function(){
      let style = {}
      if (this.height){ style['height'] = `${this.height}px`}
      if (this.width){ style['width'] = `${this.width}px`}
      return style
    }
  }
}
</script>

<style>
  .loader__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>