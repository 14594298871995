<template>
  <div v-if="retrievedReports" id="landing__container">
    <div id="landing__header">
      Select a report
    </div>
    <div id="reportList">
      <div v-for="report in orderedReports" class="reportList__item" @click="clickRow(report)">
        {{report.title}}
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'

export default{
  name:'Landing',
  data(){
    return{
      reports: {},
      retrievedReports: false
    }
  },

  computed: {
    orderedReports: function(){
      let reportsArr = Object.keys(this.reports).map( k => {
        return {
        ...(this.reports[k]),
        reportName: k
      }
      })
      reportsArr.sort( (a,b) => {if(a.title < b.title){return -1} return 0})

      return reportsArr
    }
  },
  methods: {
    clickRow: function(report){
      this.$router.replace({
        name: 'ifpReport',
        query: {
          reportName: report.reportName,
          court:report.court,
          courtTitle:report.courtTitle
        }
      })
    },

  },
  mounted(){
    api.getUserReports()
    .then( resp => {
      this.reports = resp.data.reports
      this.retrievedReports = true
    })
  }


}
</script>
<style>
#landing__container{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    border: 1px solid #d3d3d3;
    padding: 5rem 1rem 1rem 1rem;
    border-radius: 4px;
    background: #e7e7e7;
    font-family: Roboto, sans-serif;
    min-width: 600px;
    max-width: 900px;
}
#landing__header{
  width:100%;
  text-transform: uppercase;
  font-style: italic;
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
}
#reportList{
  max-height: 350px;
  overflow-y: auto;
}
.reportList__item{
  margin: 1rem;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 1rem;
  background: white;
  font-size: 2rem;
  font-weight: 300;
  font-style:italic;
}
.reportList__item:hover{
  cursor: pointer;
  color: #7434d4;
  background: #f3f1ed;
}
</style>