<template>
  <div class="report">
    <div class="title">
      <div class="title__text"> SCALES OKN Data Coverage</div>
      <div class="title__update">(Updated: {{latest_update}})</div>
    </div>
    <div class="toc">
      <div class="toc__title"> Contents </div>
      <ul>
        <li v-for="el in sectionHeaders">
          <a class="sectionLink" @click="scroll('#'+el.id)"> {{ el.innerHTML }} </a>
        </li>
      </ul>
    </div>
    <h2 id="section1">Introduction </h2>
    <div class="block"> The SCALES OKN is designed to allow systematic analysis of federal district court records. As part of that, it is important to communicate what data is available to the public for research. </div>
    <h2 id="section2"> Temporal coverage </h2>
    <div class="block"> SCALES OKN current data set includes 866,485 cases from the 94 federal courts in the period spanning from 2001 to 2022; Fig. 1. From this data set, only the years 2016 and 2017 have complete data from all 94 courts. Otherwise, the SCALES OKN data set in the Northern District of Illinois has complete temporal data for the period spanning from 2002 to 2021. </div>
    <div class="facetgrid">
      <div class="facetgrid__row">
        <Plot v-bind="barplot" v-if="barplot" :key="'barplot'" :width="900" :height="500" />
        <Loader v-else :width="450" :height="500" />
      </div>

      <div class="controlPanel controlPanel--centered">
        <md-field class="inline" style="width:170px">
          <label for="option-select"> Select a plot: </label>
          <md-select v-model="barplotActive" name="option-select">
            <md-option v-for="option in Object.keys(barplotOptionNames)" v-bind:value="option">
              {{ barplotOptionNames[option] }}
            </md-option>
          </md-select>
        </md-field>
      </div>

      <div class="facetgrid__title">
        <b>Figure 1. Bar plot of the number of cases in the SCALES OKN system in different years.</b> The plot shows the number of cases in SCALES system for each year. Data for the years 2016 and 2017 are available for the 94 federal courts. Temporal data for the period spanning from 2002 to 2021 is only available for the Northern District of Illinois.
      </div>
    </div>
    <div class="block"></div>

    <h2 id="section3"> Spatial coverage </h2>

    <div class="block">Case volume varies dramatically based on district. Courts in more populous areas generally see higher volume of cases. </div>

    <div class="facetgrid">
      <div class="facetgrid__row">
        <Plot v-bind="slidermap" v-if="slidermap" :key="'slidermap'" :width="1500" :height="800" />
        <Loader v-else :width="450" :height="500" />
      </div>

      <div class="controlPanel controlPanel--centered">
        <md-field class="inline" style="width:170px">
          <label for="option-select"> Select a plot: </label>
          <md-select v-model="slidermapActive" name="option-select">
            <md-option v-for="option in Object.keys(slidermapOptionNames)" v-bind:value="option">
              {{ slidermapOptionNames[option] }}
            </md-option>
          </md-select>
        </md-field>
      </div>

      <div class="block"> </div>
      <div class="block"> </div>
      <div class="facetgrid__title">
        <b>Figure 2. Choropleth map of number of cases in the SCALES OKN system.</b> Each region represents a federal court district and color intensity is proportional to the number of cases of that district. Use slider at the bottom of the map to select different years.
      </div>
    </div>
    <div class="block"></div>
  </div>
</template>
<script>
  import api from '@/api.js'
  // Components
  import Plot from '@/components/Plot.vue'
  import Loader from '@/components/Loader.vue'

  import {
    graphFactory
  } from '@/tools.js'
  export default {
    name: 'Report_coverage',
    components: {
      Plot,
      Loader
    },
    props: {
      reportName: String,
    },
    data: function() {
      return {
        // Barplot options 
        barplotOptionNames: {
          'all': 'All cases',
          'cr': 'Criminal cases',
          'cv': 'Civil cases'
        },
        barplotTitlePadding: {
          'all': -220,
          'cr': -220,
          'cv': -220
        },
        barplotTitles: {
          'all': "Number of cases in SCALES system by year",
          'cr': "Number of criminal cases in SCALES system by year",
          'cv': "Number of civil cases in SCALES system by year"
        },
        barplotActive: 'all',

        // slidermap options 

        slidermapOptionNames: {
          'all': 'All cases', 
          'cr': 'Criminal cases', 
          'cv': 'Civil cases'
        },
        slidermapTitlePadding: {
          'all': -220, 
          'cr': -220, 
          'cv': -220
        },
        slidermapTitles: {
          'all': "SCALES OKN Data Coverage", 
          'cr': "SCALES OKN Data Coverage",
          'cv': "SCALES OKN Data Coverage"
          },
        slidermapActive: 'all',

        pageReady: false,
        ready: false,
        sectionHeaders: null,
        latest_update: '10/10/2022',
        baseBlue: "#448aff",
      }
    },
    computed: {

      barplot: function() {
        return graphFactory(this.$store.state.graphs, '1', 'barPlot', 
        this.barplotActive, {
            'title': {
              'pad': {
                'l': this.barplotTitlePadding[this.barplotActive]
              }
            }
          },
          () => this.barplotTitles[this.barplotActive])
      },

      slidermap: function() {
        var result = graphFactory(this.$store.state.graphs, '0', 'sliderMap', 
        this.slidermapActive, {
            'title': {
              'pad': {
                'l': this.slidermapTitlePadding[this.slidermapActive]
              }
            }
          },
          () => this.slidermapTitles[this.slidermapActive])

          var map = this.$store.state.districtsMap;
          if (map === null || result === null) {
            return null;
          }
          var newValues = result.traces.map((value, index, array) => { 
            value.geojson = map;
            return value;
          });

          result.traces = newValues;
          return result;
      },
    },
    watch: {},
    methods: {
      scroll(selector) {
        const el = document.querySelector(selector)
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth'
          })
        }
      },
      getSectionHeaders() {
        const sel = 'h1:not([tocExclude]),h2:not([tocExclude])'
        return Array.from(this.$el.querySelectorAll(sel))
      },
    },
    created() {},
    mounted() {
      this.pageReady = true
      this.sectionHeaders = this.getSectionHeaders()
      this.$store.dispatch('getBarPlot', {
        reportName: this.reportName,
      })
      this.$store.dispatch('getSliderMap', {
        reportName: this.reportName,
      })
      this.$store.dispatch('getDistrictsMap', {
        reportName: this.reportName,
      })
    }
  }
</script>
<style></style>