import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import Landing from '@/components/Landing.vue'

// import ReportView from "@/components/Report_ilnd_ifp.vue"
// import ifpIlndReport from "@/data/ifp_ilnd_report.json"
// import ifpIlndGraphs from '@/data/ifp_ilnd_graphs.json'
// import ifpIlndTables from '@/data/ifp_ilnd_tables.json'

import ReportIfp from '@/components/reports/ifp.vue'
import ReportSealing from '@/components/reports/sealing.vue'
import ReportIDB from '@/components/reports/idb.vue'
import ReportCoverage from '@/components/reports/coverage.vue'

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      redirect: {
          name: "home"
      }
  },
  {
      path: "/home",
      name: "home",
      component: Home
  },
  {
      path: "/login",
      name: "login",
      component: Login
  },
  // {
  //     path: "/report",
  //     name: "report",
  //     component: ReportView,
  //     props: {...ifpIlndReport, graphs: ifpIlndGraphs, tables: ifpIlndTables}
  // },
  {
    path:'/landing',
    name:'landing',
    component: Landing
  },

  // REPORTS
  {
    path:"/ifpReport",
    name: "ifpReport",
    component: ReportIfp,
    // Passing in route params ie the info in the query string to the component as props
    props: route => ({...route.params})
  },
  {
    path:"/sealingReport",
    name: "sealingReport",
    component: ReportSealing,
    props: route => ({...route.params})
  },
  {
    path:"/idbCrosswalkReport",
    name: "idbCrosswalkReport",
    component: ReportIDB,
    props: route => ({...route.params})
  },
  {
    path:"/coverageReport",
    name: "coverageReport",
    component: ReportCoverage,
    props: route => ({...route.params})
  },
]

const router = new VueRouter({
  routes
})

export default router
