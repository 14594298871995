<template>
  <div id="app">
    <div class="nav">
      <div class="menu">
        <img id="scales_logo" src='./assets/scales_logo.png'></img>
        <span class="divider"></span>
        <span class="menu__item" style="font-weight: 600" @click="toHome">
          Home
        </span>
        <span class="menu__item" style="font-weight: 600" @click="toLogin">
          My Reports
        </span>
        <span class="spacer"></span>
        <span class="menu__item">
          <a href="https://scales-okn.org/" target="_blank">scales-okn.org</a>
        </span>
        <span class="menu__item">
          <a href="mailto:scales-okn@northwestern.edu">Contact us</a>
        </span>
        <span class="divider"></span>
        <span v-if="isAuthenticated" class="menu__item" @click="logout" style="width:110px">
          Logout
        </span>
        <span v-else class="menu__item" @click="toLogin" style="width:110px"> Login</span>
      </div>
    </div>

    <div class="content">
      <router-view @authenticated="authenticate" />
    </div>
    <div v-show="pageReady" id="scrollUpButton" @click="toTop">
      <i class="material-icons"> arrow_circle_up </i>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
import { EventBus } from "@/components/eventbus";
export default {
  name: "App",
  data() {
    return {
      isAuthenticated: false,
      pageReady: false
    };
  },
  mounted() {
    EventBus.$on("setIsAuthenticated", (value) => {
      this.isAuthenticated = value;
    });

    // if (!this.isAuthenticated) {
    //   this.toLogin();
    // }

    setTimeout(()=>{this.pageReady=true}, 0)

  },
  methods: {
    authenticate() {
      this.authenticated = true;
    },
    toHome(){
      this.$router.replace({ name: "home" }).catch((err) => {
        // Ignore the error regarding navigating to the page they are already on
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // But print any other errors to the console
          logError(err);
        }
      });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    toLogin() {
      this.$router.replace({ name: "login" }).catch((err) => {
        // Ignore the error regarding navigating to the page they are already on
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // But print any other errors to the console
          logError(err);
        }
      });
    },
    logout() {
      api.logout().then((resp) => {
        EventBus.$emit("setIsAuthenticated", false);
        this.toLogin()
      });
    },
  },
};
</script>

<style>
html{
  overflow-y: scroll;
}
body {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #f0f0f0;
  position: relative;
  line-height: normal !important;
}

#app {
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* Cannot undo vuematerial theme selection, so overwrite it with below */
#app *::selection {
  background: #adb4ff !important;
  color: white !important;
}

.nav {
  position: fixed;
  top: 0;
  width: 100vw;
  background: #4e2a84;
  border-bottom: 2px solid darkgrey;
  text-transform: uppercase;
  z-index: 1000;
}
.nav div.menu {
  display: flex;
  height: 55px;
  padding: 0 0 0 16px;
  color:#ccc3da;
}
#scales_logo{
  height: 100%;
}

.menu .spacer {
  flex-grow: 1;
}
.menu .divider{
  height: 100%;
  border-left: 1px solid #ccc3da;
}
.nav .menu .menu__item {
  font-weight: 400;
  font-size: 1.2rem;
  padding: 1rem;
  text-align: center;
}
.nav .menu__item a {
  color: inherit !important;
}
.nav .menu__item a:hover {
  text-decoration: inherit;
}

.nav .menu .menu__item:hover {
  color: #4e2a84;
  background: #f8f8f8;
  cursor: pointer;
}

#app > .content {
  margin-top: 57px;
}
h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 3rem;
  color: #424262;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 5rem 0 1rem 0;
}
.report h3 {
    font-size: 1.5rem;
}

div.report {
  background:white;
  min-width: 800px;
  max-width: 1200px;
  min-height: 1000px;
  padding: 2rem;
  margin: 2rem;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  font-size: 1.5rem;
}

/* From report */

.title {
  text-align: center;
  padding-bottom: 4rem;
}
.title__text {
  font-weight: 300;
  font-style: italic;
  font-size: 3rem;
}
.district__name {
  font-weight: 400;
  font-style: italic;
  font-size: 4rem;
}
.title__update {
  opacity: 0.7;
  font-size: 1rem;
}


.report .block {
  font-weight: 300;
  /* text-align: center; */
}

/* span.variable {
  border-bottom: 2px solid orange;
  color: orange;
  font-family: monospace;
  font-size: 1.2em;
  font-weight: 600;
} */
.variableSelect {
  margin-bottom: 1rem;
}

/* Vue Material Design components */
.md-select-menu *,
.md-select-value {
  font-size: 1.25rem !important;
}
input.md-select-value {
  -webkit-text-fill-color: #6b26d6 !important;
}
.md-table {
  border: 3px solid darkgray;
  border-radius: 4px;
  margin: 2rem;
}

.md-field.inline {
  display: inline-flex;
  width: auto;
  margin: 0px;
}

.block__wrapper {
  display: flex;
}

.block {
  margin-bottom: 1rem;
}

.spotlight{
  border: 1px solid purple;
  border-radius: 4px;
  padding: 2rem;
  margin: 1rem 4rem 1rem 4rem;
  font-family: monospace;
  word-spacing: -0.25em;
}

h2 + .block + .facetgrid {
  /* first graph in a section doesn't need extra space above */
  margin: 0 0 2rem 0;
}

.block >  ul > li{
  margin-bottom:1rem;
}

.facetgrid {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 2rem 0;
}

.facetgrid__title {
  text-align: center;
  font-style: italic;
  font-weight: 300;
  font-size: 1.4rem;
  margin-top: -0.5rem;
}
.facetgrid__row {
  display: flex;
  justify-content: center;
}
.facetgrid__row > .plot__wrapper {
  flex-grow: 1;
}
.plot__wrapper {
  display: flex;
  justify-content: center;
}

.controlPanel {
  display: flex;
  width: 100%;
  margin: 0 0 2rem 0;
}
.controlPanel--centered {
  justify-content: space-evenly;
}

table.aggTable {
  width: 100%;
  font-weight: 300;
  /* border: 1px solid grey; */
  margin: 2rem 0 2rem 0;
  text-align: center;
}
table.aggTable th {
  font-weight: 500;
  font-style: italic;
  text-transform: uppercase;
}

table.aggTable td,
table.aggTable th {
  background: #d0e2f8;
}

table.aggTable.aggTable--prisoner td,
table.aggTable.aggTable--prisoner th {
  background: #efd7a3;
}
table.aggTable tr:hover td,
table.aggTable tr:hover:not(:nth-child(1)) th {
  background: #92c2fc;
  color: white;
}

span.fig {
  font-family: monospace;
  word-spacing: -0.25em;
}

span.code {
  font-family: monospace;
  font-size: 0.8em;
  background: #f6f6f6;
  border: 1px solid lightgrey;
  padding: 0 5px 0 5px;
  border-radius: 4px;
}

span.code.code--var {
  color: #6b26d6;
}

details.footnote {
  font-size: 0.8em;
  font-weight:300;
  background: #f0f0f0;
  border-radius: 4px;
  margin: 1rem;
  padding: 1rem;
}
details.footnote summary {
  font-style: italic;
  font-weight: 400;
  text-transform: uppercase;
  color: grey;
  padding-left: 1rem;
}
details.footnote[open] summary {
  margin-bottom: 1rem;
}
details.footnote summary:focus {
  outline: none !important;
}
details.footnote summary:hover {
  background: #d1dfff;
  border-radius: 4px;
  cursor: pointer;
}

.sectionLink {
  cursor: pointer;
}

.toc {
  font-weight: 300;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 1rem;
  background: #f0f0f0;
}
.toc__title {
  text-transform: uppercase;
  font-style: italic;
  font-weight: 400;
}
.toc li a {
  color: #7434d4 !important;
}

#scrollUpButton {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 10px;
  cursor: pointer;
  user-select: None;
}
#scrollUpButton i {
  font-size: 4rem;
  color: #4e2a84;
  text-shadow: 0px 3px 8px grey;
  font-display: swap
}

.infoBox {
  margin-top: 2rem;
  background: #733fc236;
  padding: 1rem;
  border-radius: 4px;
  color: darkslategrey;
  font-weight: 300;
}
div.note {
  display: flex;
  justify-content: center;
}
div.note .note__content {
  background: #ffa5004d;
  font-style: italic;
  font-weight: 300;
  padding: 0.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}


.latex {
  text-align:center;
  margin: 1rem;
}

.zenodo{
  vertical-align: inherit;
}

.bold-term{
  font-style:italic;
  font-weight:400;
}

</style>