<template>
  <div id="home">
    <div class="top-section">
      <h1> Public Dashboards </h1> <p class="subheading"> A collection of insights generated from the SCALES OKN project. </p>
    </div>
    <div id="home-content">
      <div id="public-reports" class="reports-panel">
        <div class="panel__entry" @click="clickRow('sealingReport')">
          <div class="panel__entry__left">
            <img :src="images.lock">
          </div>
          <div class="panel__entry__right">
            <div class="heading"> Sealing Activity </div>
            <div class="description"> A cross-district look into the practice of sealing documents and docket entries within 2016 patent cases. </div>
          </div>
        </div>
        <div class="panel__entry" @click="clickRow('idbCrosswalkReport')">
          <div class="panel__entry__left">
            <img :src="images.crosswalk">
          </div>
          <div class="panel__entry__right">
            <div class="heading"> IDB Crosswalk </div>
            <div class="description"> Does the FJC's Integrated Data Base correctly capture the contents of PACER docket sheets? </div>
          </div>
        </div>
        <div class="panel__entry" @click="clickRow('coverageReport')">
          <div class="panel__entry__left">
            <img :src="images.network">
          </div>
          <div class="panel__entry__right">
            <div class="heading"> SCALES OKN Data Coverage</div>
            <div class="description"> How comprehensive is the SCALES OKN dataset? </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import api from '@/api.js';
  import {
    EventBus
  } from '@/components/eventbus'
  import ImgLock from "@/assets/lock.jpg"
  import ImgCrosswalk from "@/assets/crosswalk.jpg"
  import ImgNetwork from "@/assets/network.jpg"
  export default {
    name: 'Home',
    data() {
      return {
        msg: 'Hello',
        images: {
          lock: ImgLock,
          crosswalk: ImgCrosswalk,
          network: ImgNetwork
        }
      }
    },
    methods: {
      clickRow: function(pathName) {
        this.$router.replace({
          name: pathName,
          params: {
            reportName: 'sealing'
          }
        })
      }
    },
    beforeCreate() {}
  }
</script>
<style scoped>
  #home {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    margin-bottom: 7px;
  }

  .subheading {
    margin: 0;
    text-align: center;
  }

  .top-section {
    margin-bottom: -36px;
  }

  #home-content {
    margin-top: 3rem;
  }

  .panel__entry {
    display: flex;
    min-height: 150px;
    width: 800px;
    background: white;
    border-radius: 4px;
    /* padding: 2rem; */
    margin: 2rem;
    border: 1px solid #d7d7d7;
  }

  .panel__entry:hover {
    cursor: pointer;
    background: #f3f1ed;
    border-color: #aa92cd;
  }

  .panel__entry>div {
    padding: 1rem;
  }

  .panel__entry__left {
    width: 40%;
  }

  .panel__entry__right {
    width: 50%;
  }

  .panel__entry__right .heading {
    font-size: 2.25rem;
    /* font-style: italic; */
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 1rem
  }

  .panel__entry:hover .heading {
    color: #7434d4;
  }

  .panel__entry__right .description {
    font-style: italic;
    font-size: 1.1rem;
  }

  .panel__entry.pending {
    opacity: 0.5;
  }

  .placeholder-image {
    content: "placeholder image";
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    min-height: 200px;
  }
</style>