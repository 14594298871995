<template>
  <div class="report">
    <div class="title">
      <div class="title__text">IFP Analysis for</div>
      <div class="district__name">{{ courtTitle }}</div>
      <div class="title__update">(Updated: {{latest_update}})</div>
    </div>

    <div class="toc">
      <div class="toc__title"> Contents </div>
      <ul>
        <li v-for="el in sectionHeaders">
          <a class="sectionLink" @click="scroll('#'+el.id)"> {{ el.innerHTML }} </a>
        </li>
      </ul>
    </div>

    <h2 id="section1">1. Data Description </h2>
    <div class="block">
      For this analysis, we have pulled all {{ court | uppercase }} case activity
      available in the SCALES-OKN database,
      yielding a dataset that includes every case filed in the {{courtTitle}}
      between Jan 1, {{this.yearRange[0]}} and Dec 31, {{this.yearRange[1]}}.
    </div>

    <h2 id="section2">2. Methodology</h2>

    <div class="block">
      Similar to the Federal Judicial Commission’s Integrated Database, we only record
      one <i>in forma pauperis</i> (ifp) application result per case.
      If we detect that the application was <em>granted</em>,
      then we count that "granted" outcome towards the judge presiding over the case
      at the time of the grant.
      Grants take priority over denials in our dataset; in other words, if both
      a denial and a grant are detected in the same case, then we code a "granted" outcome.
    </div>

    <div class="block">
      <details class="footnote">
        <summary>How we identify <i>in forma pauperis</i></summary>
        To identify potential cases we search the docket entry text for any direct
        mention of <span class="code">pauperis</span>, <span class="code">ifp</span>,
        <span class="code">without prepay</span>, <span class="code">without the pay</span>,
        <span class="code">without pay</span>, and <span class="code">1915</span>.
        We then use an algorithm to determine whether the motion was ultimately
        granted, denied, or reached an indeterminate outcome.
        <br/></br/>

        We employ regular expressions to identify <i>in forma pauperis</i> applications
        and their outcome. A regular expression is a sequence of characters that define
        a flexible search pattern; for example, the regular expression
        <span class="code">grant(?!(s|ed|ing))</span> will match to the written text of
        <span class="code">grant</span>, <span class="code">grants</span>,
        <span class="code">granted</span>, and <span class="code">granting</span>.
        Based on empirical evaluation of docket entry text, we construct an independent
        set of regular expressions to identify the application and the potential outcomes of
        <span class="code">grant</span>, <span class="code">deny</span> and <span class="code">other</span>.
        <br/></br/>

        We search every sentence independently in each docket entry with these regular
        expressions to determine if an <i>in forma pauperis</i> application was made or
        an outcome was decided on the application. For entries that we identify as an
        <span class="code">application</span>,  <span class="code">grant</span>,
        <span class="code">deny</span>, or <span class="code">other</span>
        we apply a filter to identify the final application in the case. We then identify
        what outcomes reference this application.
        <br/></br/>


        If there is one outcome, then that docket entry is chosen as the final outcome.
        If there are multiple outcomes then a scoring algorithm is used that weights
        the use of words that denote a decision
        (<span class="code">ORDER</span>, <span class="code">DECISION</span>, <span class="code">OPINION</span>)
        and the identified outcome, with preference given to <span class="code">grant</span> outcomes.
        The identified outcome with the highest score is then chosen as the final outcome
        for the application.
        <br/></br/>

        We constructed a test set of 188 cases that are stratified across all 94 districts
        and labelled the final outcome by hand. When we run the algorithm on this test
        set we find that it correctly identifies the final outcome in 97.9{{'%'}} cases.

      </details>
    </div>

    <div class="block">
      <details class="footnote">
        <summary>How we determine the deciding judge</summary>
        We use natural language processing to identify all judges associated with a case.
        As a part of this process, we have trained a custom AI algorithm to detect the
        written mention of a judge's name in docket text. The lower bounds on the
        performance of this classifier in identifying a judge in text is 97{{'%'}}
        for both sensitivity and precision.
        <br/></br/>

        Algorithmically identified mentions of judges are then processed through an
        entity disambiguation pipeline. This pipeline resolves name variations and
        misspellings to a single judge entry in the Federal Judicial Center Biographical
        Directory based on name similarity and the date and jurisdiction where the activity occurred.
        <br/></br/>
        If an identified judge is mentioned in the same docket entry as the identified
        final outcome of the <i>in forma pauperis</i>, then that judge is assigned
        as the deciding judge for the application.
        If there is no identified mention of a judge in final outcome docket entry,
        we attempt to find previous inline mentions on the preceding IFP application activity,
        and if that fails, we use the assigned case judge.

      </details>
    </div>

    <div class="block">
      <details class="footnote">
        <summary>Note on Nature-of-Suit Codes</summary>
          Each case in the dataset is grouped into one of two categories,
          <span class="fig">Prisoner</span> and <span class="fig">Non-Prisoner</span>.
          To identify Prisoner</span> cases, we use the following nature-of-suit codes:
          <ul>
            <li> <span class="fig">510</span>: Prisoner Petitions: Motions to Vacate Sentence</li>
            <li> <span class="fig">530</span>: Prisoner Petitions: General</li>
            <li> <span class="fig">535</span>: Prisoner Petitions: Death Penalty</li>
            <li> <span class="fig">540</span>: Prisoner Petitions: Mandamus and Other</li>
            <li> <span class="fig">550</span>: Prisoner Petitions: Civil Rights</li>
            <li> <span class="fig">555</span>: Prisoner Petitions: Prison Condition</li>
            <li> <span class="fig">560</span>: Prisoner Petitions: Civil Detainee</li>
          </ul>
          Additionally, all <em>ifp</em> matches found in criminal cases are included as <span class="fig">Prisoner</span> cases.
          All other cases are considered <span class="fig">Non-Prisoner</span> cases.
      </details>
    </div>

    <h2 id="section3">3. Summary Graphs </h2>

    <div class= "block">
      In the following graphs we plot the percentage of <i>in forma pauperis</i>
      applications that an individual judge grants. We calculate the grant percentage
      as the number of applications granted divided by the total number of applications
      that the judge oversaw that were granted or denied.
    </div>

    <div class="facetgrid">
      <div class="facetgrid__row">
        <!-- <Plot v-bind="graph1" v-if="pageReady"  :width="700" /> -->
        <Plot v-bind="graph1" v-if="graph1" :key="'graph1'"   :width="700" />
        <Loader v-else :width="700" :height="500"/>

      </div>
      <div class="facetgrid__title"> Figure 1 </div>
    </div>

    <div class="block">
      Figure 1 shows the distribution of ifp grant rates per judge in the {{courtTitle}}
      during {{yearRangeString}}. (Each bar of the histogram indicates how many judges grant
      <em>ifp</em> petitions at a given percentage rate.) We find that there
      <span class="fig">{{isSigAll ? 'is': 'is not'}}</span>
      statistically significant variation between the grant percentages of individual judges.
      <span  v-if="isSigExcl!==undefined">
        If we only examine "non-prisoner cases" and remove outlier judges,
        there <span class="fig">{{isSigExcl ? 'is': 'is not'}}</span> statistically significant variation
        between individual judges{{isSigAll && isSigExcl ? ' also':''}}.
      </span>
    </div>
    <details class="footnote">
      <summary>Statistical Note</summary>
      We do not attempt to identify what the percentage of cases should be granted
      a waiver when we estimate significance. Instead, our approach relies on the
      fact that cases are (largely) randomly assigned. Thus, we expect that the difference
      between an individual judge’s grant rate and the grant rate of all other judge’s
      in the district to be small.
      <br/></br>

      For each judge we estimate the difference in the grant rate of that judge
      in comparison to all other judges in the district. With a standard unpaired
      t-test we calculate the 95{{'%'}} confidence interval on the estimate of how
      much the individual judge varies from the population. If the range of the
      confidence interval is solely positive or negative, then that individual is
      determined to significantly vary from their peers.
      <br/></br>

      We find that <span class="fig">{{jsumSigAll}}</span> out of
      <span class="fig">{{jcountSigAll}}</span> judges significantly vary from their
      peers in the district. This <span class="fig">{{isSigAll ? 'is': 'is not'}}</span>
      significantly more than the five percent that we would expect to differ by random chance.
      <br/></br>

      <span  v-if="isSigExcl!==undefined">
        If we only consider non-prisoner cases, and exclude outlier judges (with
        grant rates less than 5{{'%'}} or greater than 95{{'%'}}), we find that
        <span class="fig">{{jsumSigExcl}}</span> out of <span class="fig">{{jcountSigExcl}}</span>
        vary from their peers in the district.
        This <span class="fig">{{isSigExcl ? 'is': 'is not'}}</span>
        significantly more than the five percent that we would expect to differ by random chance.

      </span>

    </details>

    <div class="facetgrid">
      <div class="facetgrid__row">
        <div class="plot__wrapper">
          <Plot v-bind="graph2_1" v-if="graph2_1!=null" :key="graph2_1.uuid" :width="450"/>
          <Loader v-else :width="450" :height="450"/>
        </div>
        <div class="plot__wrapper">
          <Plot v-bind="graph2_2" v-if="graph2_2!=null" :key="graph2_2.uuid" :width="450"/>
          <Loader v-else :width="450" :height="450"/>
        </div>
      </div>
      <div class="facetgrid__title" style="margin-top: -2rem"> Figure 2 </div>
    </div>

    <div class="block">
       Figure 2 shows the distribution of <em>ifp</em> grant percentages per judge
       in the {{courtTitle}} for non-prisoner and prisoner cases. This comparison reveals that
       the variation between judges' grant rates is
        <span class="fig"> {{jgpPrisonerStdDev > jgpNonprisonerStdDev ? 'larger' : 'smaller'}} </span>
       for prisoner cases than for non-prisoner cases.
     </div>
     <div class="block">
       For prisoner cases, the average judge in the {{courtTitle}} grants
       <span class="fig">{{jgpPrisonerAvg|percentage(0)}}</span> of <em>ifp</em> applications,
       and the standard deviation is <span class="fig">{{jgpPrisonerStdDev|percentage(0)}}</span>.
       For non-prisoner cases, an average of <span class="fig">{{jgpNonprisonerAvg|percentage(0)}}</span>
       of applications are granted, with a standard deviation of
       <span class="fig">{{jgpNonprisonerStdDev|percentage(0)}}</span>.

    </div>

    <div class="facetgrid" id="graph3">
      <div class="facetgrid__row">
        <div class="plot__wrapper">
          <Plot v-bind="graph3_0" v-if="graph3_0" :key="graph3_0.uuid" :width="700"/>
          <Loader v-else :width="700" :height="500"/>
        </div>
      </div>
      <div class="facetgrid__title"> Figure 3 </div>

      <div class="controlPanel controlPanel--centered">
        <md-field class="inline" style="width:100px">
          <label for="year-select"> Year</label>
          <md-select v-model="selectedGraph3Year" name="year-select">
            <md-option v-for="yearOption in graph3Options.year" v-bind:value="yearOption">
              {{ yearOption }}
            </md-option>
          </md-select>
          <!-- <span class="qmarkWrapper"> <i class="material-icons" style="color: orange;">help</i></span> -->
        </md-field>
        <md-field class="inline" style="width:200px">
          <label for="year-select"> Category</label>
          <md-select v-model="selectedGraph3Prisoner" name="year-select">
            <md-option v-for="prisonerOption in graph3Options.prisoner" v-bind:value="prisonerOption.val">
              {{ prisonerOption.display }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <div v-if="this.court!='ilnd'" class="note">
        <span class="note__content">
          Note: only data from <span style="font-style:normal">2016</span> to <span style="font-style:normal">2017</span> is available for the
          {{courtTitle}} (see <a @click="scroll('#dataAvailability')" class="sectionLink">below</a>).
        </span>
      </div>
    </div>

    <div class="block">
      Figure 3 shows the distribution of judges' grant percentages, broken up both
      by year and by nature-of-suit category (prisoner vs non-prisoner).
    </div>



    <div class="facetgrid">
      <div class="facetgrid__row">
        <div class="plot__wrapper">
          <Plot v-bind="graph4_1" v-if="graph4_1" :key="graph4_1.uuid" :width="450"/>
          <Loader v-else :width="450" :height="450"/>
        </div>
        <div class="plot__wrapper">
          <Plot v-bind="graph4_2" v-if="graph4_2" :key="graph4_2.uuid" :width="450"/>
          <Loader v-else :width="450" :height="450"/>
        </div>
      </div>
      <div class="facetgrid__title" style="margin-top: -3rem"> Figure 4 </div>
    </div>

    <div class="block">
      Figure 4 gives more information about typical grant percentages during each year.
      The edges of the filled-in box delimit the 25th and 75th percentiles of individual judge
      grant rates within a given year, and the middle line within the box specifies the
      median grant rate. The upper and lower "fence" bars suggest a broader range within
       which most judges' grant rates fall, and any dots outside of those fences indicate outliers.
    </div>

    <h2 id="section4"> 4. Comparison to National Distribution</h2>
    <div class="block">

      To put the above data in context, we have used additional data from the SCALES OKN
      to plot the national distribution of grant rates, using data from all 94 districts.

    </div>

    <div class="facetgrid" id="graph5">
      <div class="facetgrid__row" style="border-bottom: 1px solid darkgrey;">
        <div class="plot__wrapper">
          <Plot v-bind="graph5_1_1" v-if="graph5_1_1"   :key="'graph5_1_1'" :width="450" :height="350"/>
          <Loader v-else :width="450" :height="350"/>
        </div>
        <div class="plot__wrapper">
          <Plot v-bind="graph5_1_2" v-if="graph5_1_2"  :key="'graph5_1_2'" :width="450" :height="350"/>
          <Loader v-else :width="450" :height="350"/>
        </div>
      </div>
      <div class="facetgrid__row">
        <div class="plot__wrapper">
          <Plot v-bind="graph5_2_1" v-if="graph5_2_1" :key="'graph5_2_1'" :width="450" :height="350"/>
          <Loader v-else :width="450" :height="350"/>
        </div>
        <div class="plot__wrapper">
          <Plot v-bind="graph5_2_2" v-if="graph5_2_2" :key="'graph5_2_2'" :width="450" :height="350"/>
          <Loader v-else :width="450" :height="350"/>
        </div>
      </div>
      <div class="facetgrid__title" style="margin: -2rem 0 1rem 0"> Figure 5 </div>

    </div>

    <div class="block">
      Figure 5 compares the distribution of judges' grant percentages in the {{courtTitle}}
      with the national distribution across all districts in 2016-2017 (the timespan for which we have complete cross-district data),
      broken down by prisoner and non-prisoner subcategories.
    </div>

    <h2 id="dataAvailability" tocExclude>Data Availability</h2>
    <div class="infoBox">
      For most districts, only data from 2016 to 2017 is available.
      If you would like to see analysis of additional years for your district,
      please feel free to contact us at
      <a href="mailto:scales-okn@northwestern.edu">scales-okn@northwestern.edu</a>.

    </div>


  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import api from '@/api.js'
import court2circuit from '@/data/court2circuit.js'


// Components
import Plot from '@/components/Plot.vue'
import Loader from '@/components/Loader.vue'
import DocketSnippet from '@/components/DocketSnippet'
import _merge from 'lodash/merge'
import _cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'Report_ifp',
  components: {
    Plot,
    DocketSnippet,
    PulseLoader,
    Loader
  },
  data: function(){
    return {
      pageReady: false,
      ready: false,
      reportName: null,
      court: null,
      courtTitle: null,
      selectedIndex: 0,
      selectedOtherCourt: 'cacd',
      selectedOtherCircuit: 'First',
      selectedGraph3Year: 2016,
      selectedGraph3Prisoner: 'NonPrisoner',
      sectionHeaders: null,
      selectedOtherCourtLeveneStatistic: null,
      selectedOtherCourtLeveneP: null,
      latest_update: '11/29/2021',
      baseBlue: "#448aff",
      court2circuit: court2circuit
    }
  },

  computed: {
    thisCircuit: function(){
      return court2circuit[this.court]
    },
    // comparisonCourtLevStat: function(){
    //   const statKey = `${this.selectedOtherCourt}2016NonPrisoner`
    //   return this.$store.state.leveneStats[statKey]?.statistic
    // },
    // comparisonCourtLevP: function(){
    //   const statKey = `${this.selectedOtherCourt}2016NonPrisoner`
    //   return this.$store.state.leveneStats[statKey]?.pvalue
    // },

    comparisonCircuitLevStat: function(){
      const statKey = `${this.selectedOtherCircuit}2016NonPrisoner`
      return this.$store.state.leveneStats[statKey]?.statistic
    },
    comparisonCircuitLevP: function(){
      const statKey = `${this.selectedOtherCircuit}2016NonPrisoner`
      return this.$store.state.leveneStats[statKey]?.pvalue
    },

    yearRange: function(){
      let baseStats = this.$store.state.ifpBaseStats
      if(this.court != null && this.court in baseStats){
        return this.$store.state.ifpBaseStats[this.court].yearRange
      }
      else{
        return [null,null]
      }
    },
    yearRangeString: function(){
      if (this.yearRange[0] !== this.yearRange[1]) {
        return ''+this.yearRange[0]+'-'+this.yearRange[1]
      }
      else {
        return ''+this.yearRange[0]
      }
    },

    jgpNonprisonerAvg: function(){
      // let baseStats = this.$store.state.ifpBaseStats
      // if(this.court != null && this.court in baseStats){
      //   return this.$store.state.ifpBaseStats[this.court].jgpNonprisonerAvg
      // }else{
      //   return null
      // }
      return this.$store.state.ifpBaseStats[this.court]?.jgpNonprisonerAvg
    },
    jgpNonprisonerStdDev: function(){return this.$store.state.ifpBaseStats[this.court]?.jgpNonprisonerStdDev},
    jgpPrisonerAvg: function(){return this.$store.state.ifpBaseStats[this.court]?.jgpPrisonerAvg},
    jgpPrisonerStdDev: function(){return this.$store.state.ifpBaseStats[this.court]?.jgpPrisonerStDev},
    isSigAll: function(){return this.$store.state.ifpBaseStats[this.court]?.judgeVarSig.all.isSig},
    jsumSigAll: function(){return this.$store.state.ifpBaseStats[this.court]?.judgeVarSig.all.jsum},
    jcountSigAll: function(){return this.$store.state.ifpBaseStats[this.court]?.judgeVarSig.all.jcount},
    isSigExcl: function(){return this.$store.state.ifpBaseStats[this.court]?.judgeVarSig?.exclPrisonerOutliers?.isSig},
    jsumSigExcl: function(){return this.$store.state.ifpBaseStats[this.court]?.judgeVarSig?.exclPrisonerOutliers?.jsum},
    jcountSigExcl: function(){return this.$store.state.ifpBaseStats[this.court]?.judgeVarSig?.exclPrisonerOutliers?.jcount},

    // Graphs
    graph1: function(){return this.graphFactory('1', 'summary', `${this.court}`)},

    graph2_1: function(){
      return this.graphFactory('2_1', 'byPrisoner', `${this.court}AllNonPrisoner`,{},()=>'Non-Prisoner Cases')
    },
    graph2_2: function(){
      return this.graphFactory('2_2', 'byPrisoner', `${this.court}AllPrisoner`,{},()=>'Prisoner Cases')
    },

    // Graph 3: user selected by year by Prisoner
    graph3_0: function(){
      // let title = typeof this.court !== undefined ? 'Non-Prisoner Cases' : 'placeholder'
      // this.graph3Options.prisoner.re
      let title_category = this.graph3Options.prisoner.filter( p => p.val==this.selectedGraph3Prisoner)[0].display
      let title = `${title_category} Cases, ${this.selectedGraph3Year}`
      return this.graphFactory('3_0', 'byYearByPrisoner',
          `${this.court}${this.selectedGraph3Year}${this.selectedGraph3Prisoner}`, {}, ()=>title)
    },

    graph3Options: function(){
      let alternatives = this.$store.state.graphs?.byYearByPrisoner?.alternatives
      // Default year is just 2016
      let years = [2016]
      if (alternatives){
        // Get all the keys that match this court
        let courtKeys = Object.keys(alternatives).filter(x=>x.startsWith(this.court))
        // Get the set of years
        const n = this.court.length
        let yearsSet = new Set(courtKeys.map(x=>Number(x.slice(n, n+4))))
        years = Array.from(yearsSet)
      }
      return {
        year: years,
        prisoner: [
          {'val':'All', 'display': 'All'},
          {'val':'Prisoner', 'display': 'Prisoner'},
          {'val':'NonPrisoner', 'display': 'Non-Prisoner'}
        ]
      }
    },
    // Graph 4 boxplots
    graph4_1: function(){
      let title = typeof this.court !== undefined ? 'Non-Prisoner Cases' : 'placeholder'
      return this.graphFactory("4_1", 'boxplotByYearByPrisoner', `${this.court}NonPrisoner`,{}, ()=>title)
    },
    graph4_2: function(){
      let title = typeof this.court !== undefined ? 'Prisoner Cases' : 'placeholder'
      return this.graphFactory("4_2", 'boxplotByYearByPrisoner', `${this.court}Prisoner`,{}, ()=>title)
    },


    // Graph 5 Other courts
    graph5_1_1: function(){return this.graphFactory("5_1_1", 'byCourtByPrisoner',`${this.court}AllNonPrisoner`)},
    graph5_1_2: function(){return this.graphFactory("5_1_2", 'byCourtByPrisoner',`usaAllNonPrisoner`)},
    graph5_2_1: function(){return this.graphFactory("5_2_1", 'byCourtByPrisoner',`${this.court}AllPrisoner`)},
    graph5_2_2: function(){return this.graphFactory("5_2_2", 'byCourtByPrisoner',`usaAllPrisoner`)},
    // graph5_options: function(){
    //   const fullList = this.$store.state.courtComparisonOptions
    //   return fullList.filter( x => x!=this.court)
    // },

    // graph5c_1_2: function(){return this.graphFactory("5_1_2", 'byCircuitByPrisoner2016',`${this.selectedOtherCircuit.toLowerCase()}2016NonPrisoner`)},
    // graph5c_2_2: function(){return this.graphFactory("5_2_2", 'byCircuitByPrisoner2016',`${this.selectedOtherCircuit.toLowerCase()}2016Prisoner`)},

    // graph5c_options: function(){
    //   return ['First','Second', 'Third', 'Fourth', 'Fifth', 'Sixth','Seventh',
    //    'Eighth',  'Ninth','Tenth','Eleventh' ]
    // }
  },

  watch: {
    // selectedOtherCourt: function(newCourt, oldCourt){
    //   const year = 2016
    //   this.$store.dispatch('fetchCourtComparison',
    //   {reportName:this.reportName, baseCourt:this.court, comparisonCourt:newCourt, year:year})
    // },
    // selectedOtherCircuit: function(newCircuit, oldCircuit){
    //   const year = 2016
    //   this.$store.dispatch('fetchCircuitComparison',
    //   {reportName:this.reportName, baseCourt:this.court, circuit:newCircuit, year:year})
    // }
  },

  methods:{
    graphYMax(graphRefs, factor=1.1, addition=1){
      const max = Math.max(...graphRefs.map( g=> this.$refs[g]?.$el.layout.yaxis.range[1]))
      return Math.ceil(max * factor) + addition
    },

    scroll(selector){
      const el = document.querySelector(selector)

      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    },


    graphFactory(ind, graphKey, altKey, additionalLayout, titleTextFn=(alt)=>alt[0].name){
      // Main function to build graphs
      // @param {String} ind: the index of the graph1
      // @param {String} graphKey: the key to look up graph in the store
      // @param {String} altKey: the key for this choice of data alternative within the graph
      // @param {Object} additionalLayout: extra layout options to pass into graph
      // @param {function} titleTextFn: function to build the title text, can access the graph alternative
      //
      // @return {Object} the graph object to be passed to the Plot component

      let graphId = `${ind}_${altKey}`
      let graphs = this.$store.state.graphs

      if( (graphKey in graphs) && (altKey in graphs[graphKey].alternatives) ){
        let graphObj = graphs[graphKey]
        let titleText =  titleTextFn(graphObj.alternatives[altKey])
        let layout = _merge(_cloneDeep(graphObj.layout), additionalLayout, {title: {text: titleText}})
        let graph = {
          uuid: "graph_"+ graphId,
          traces:  graphObj.alternatives[altKey],
          layout: layout,
          config: graphObj.config
        }
        return graph
      }
      else{
        return null
      }
    },

    getSectionHeaders(){
      const sel = 'h1:not([tocExclude]),h2:not([tocExclude])'
      return Array.from(this.$el.querySelectorAll(sel))
    },

  },

  created(){
    this.reportName = this.$route.query.reportName
    this.court = this.$route.query.court
    this.courtTitle = this.$route.query.courtTitle
  },

  mounted(){

    // before initializing, make sure that the user is logged in...
    api.checkAuthenticated()
    .then ( res=>{
      if (!res.data.authenticated) {
        this.$router.replace('home')
      }
    })
    // ...and that they have been granted access to this report
    api.getUserReports()
    .then ( res=>{
      if (!(this.reportName in res.data.reports)) {
        this.$router.replace('landing')
      }
    })

    this.$store.dispatch('initializeIfp',  {
      reportName: this.reportName,
      court: this.court
    })

    this.pageReady = true
    // console.log('page ready')
    this.sectionHeaders = this.getSectionHeaders()

    // Staggered calls: first graph, then main graphs, then comparison
    this.$store.dispatch('getIfpFirstGraph', {
      reportName: this.reportName,
      court: this.court
    })
    .then( () => {
      return this.$store.dispatch('getMainGraphs', {
        reportName: this.reportName,
        court: this.court
      })
    })
    // .then( () => {
    //   this.$store.dispatch('fetchCourtComparisonOptions', {
    //     reportName: this.reportName,
    //     year:2016
    //   })
    // })

    // Trigger the comparison
    // this.selectedOtherCourt = 'azd'
    // this.selectedOtherCircuit = this.thisCircuit
    // this.$store.dispatch('fetchCircuitComparison',
    //   {reportName:this.reportName, baseCourt:this.court, circuit:this.selectedOtherCircuit, year:2016})

  }
}
</script>

<style>

  .graph3SmallGrid{
    width:250px;
    height:250px;
  }
  .graph5GridOf4{
    width: 350px;
    height: 350px;
  }

  .graph5All{
   width:350px;
   height:350px;
  }

  .test_blank{
    width:350px;
    height:350px;
  }

</style>
