<!-- A component to simplify displaying a docket snippet -->
<template >
  <div>
    <md-table>
      <!-- Header -->
      <md-table-row>
        <md-table-head>Date Filed </md-table-head>
        <md-table-head md-numeric>ID</md-table-head>
        <md-table-head>Docket Entry</md-table-head>
      </md-table-row>

      <!-- Rows -->
      <md-table-row v-for="(row,scales_ind) in rows" >
        <md-table-cell>{{row.date_filed}}</md-table-cell>
        <md-table-cell md-numeric>{{row.ind}}</md-table-cell>
        <md-table-cell v-html="row.inner_html"></md-table-cell>
      </md-table-row>

    </md-table>
  </div>
</template>

<script>

export default {
  props: {
    rows: Array
  }
}
</script>
