import _merge from 'lodash/merge'
import _cloneDeep from 'lodash/cloneDeep'

export function graphFactory(graphs, ind, graphKey, altKey, additionalLayout, titleTextFn=(alt)=>alt[0].name){
  // Main function to build graphs
  // @param {Object} graphs: the current graphs state object
  // @param {String} ind: the index of the graph1
  // @param {String} graphKey: the key to look up graph in the store
  // @param {String} altKey: the key for this choice of data alternative within the graph
  // @param {Object} additionalLayout: extra layout options to pass into graph
  // @param {function} titleTextFn: function to build the title text, can access the graph alternative
  //
  // @return {Object} the graph object to be passed to the Plot component

  let graphId = `${ind}_${altKey}`
  // let graphs = this.$store.state.graphs

  if( (graphKey in graphs) && (altKey in graphs[graphKey].alternatives) ){
    let graphObj = graphs[graphKey]
    let titleText =  titleTextFn(graphObj.alternatives[altKey])
    let layout = _merge(_cloneDeep(graphObj.layout), additionalLayout, {title: {text: titleText}})
    let graph = {
      uuid: "graph_"+ graphId,
      traces:  graphObj.alternatives[altKey],
      layout: layout,
      config: graphObj.config
    }
    return graph
  }
  else{
    return null
  }
}